import React from 'react';
import '../css/Blog.css'; // Import the CSS file for the Blog page
import BlogCard from '../components/BlogCard';

const Blog = () => {
  return (
    <div className="blog-page">

      <div className="blog-content">
        <h1>Welcome to My Blog</h1>
        <p>This is where I'll share my thoughts, updates, and more. </p>
        <BlogCard
          title="My First Blog Post!"
          summary="This is a summary of my first post..."
          imageUrl="../images/boeing.jpg"
          link="/blog/my-first-post"
        />
        <BlogCard
          title="My First Blog Post!"
          summary="This is a summary of my first post..."
          imageUrl="../images/boeing.jpg"
          link="/blog/my-first-post"
        />

      </div>
    </div>
  );
};

export default Blog;
