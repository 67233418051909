import React from 'react';
import '../css/Experience.css';
import Card from './Card';

const Experience = () => (
  <section id="experience" className="content-Experience">
    <h2>Experience</h2>
    <Card
      picture={require("../images/caltrans.png")}
      title="Caltrans - Electrical Engineering Intern"
      description="TBD"
      tags={[]}
    />
    <Card
      picture={require("../images/boeing.png")}
      title="The Boeing Company - Test Automation Engineer"
      description="Identify bugs for various Mission Accelerator application relating to UI/UX, Web APIs, and Responsiveness. Developed and executed detailed test cases for both functional and non-functional requirements based off the Software Development Life Cycle. Utilized Testcomplete for automation testing."
      tags={[]}
    />
    <Card
      picture={require("../images/cci.png")}
      title="California Cybersecurity Institute - Software developer"
      description="Revamped legacy code of previous discord bots into modern, maintainable, and robust code for future development. Fixed internal structure and UI/UX functionality, allowing users to understand the bot intuitively and drastically reduced the number of errors users could make.
      Developed python-based bots that allow users to request help using a support ticket system (100+ tickets created), allowed users to verify based on an email (200+ students verified), and several commands to help moderate and maintain the discord server. 
      Incorporated AWS services like DynamoDB, EC2-Instances, and CodeCommit for efficient and reliable bot functionality, resulting in a better user experience.
      "
      tags={[]}
    />
    <Card
      picture={require("../images/ebay.jpg")}
      title="Ebay - Independent Seller"
      description="+800 verified sales, +1.5k total sales, +10k profit, +150 vouches. Responsible for sales, shipping, inventory management, customer service, & quality control of products. Main products sold: Roblox(currency, limited edition items, in-game cosmetics, in-game pets), study guides, coding services in automation."
      tags={[]}
    />

  </section>
);

export default Experience;
