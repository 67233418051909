import React, { useState } from 'react';
import Modal from 'react-modal';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import '../css/Card.css';

Modal.setAppElement('#root');

const Card = ({ picture, title, description, tags, buttons, carousel }) => {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const hasCarouselImages = carousel && carousel.length > 0;
  const toggleModal = () => {
    // Only toggle modal if carousel has items
    if (carousel && carousel.length > 0) {
      setModalIsOpen(!modalIsOpen);
    }
  };

  return (
    <>
      <div className="card-container">
      <div className={`card-image-container ${hasCarouselImages ? 'pointer-cursor' : ''}`} onClick={hasCarouselImages ? toggleModal : undefined}>
          <img className="card-image" src={picture} alt={title} />
        </div>
        <div className="card-content">
          <h2 className="card-title">{title}</h2>
          <p className="card-description">{description}</p>
          <div className="card-tags">
            {tags.map((tag, index) => <span key={index} className="tag">{tag}</span>)}
          </div>
          <div className="card-buttons">
            {buttons && buttons.map((button, index) => 
              <a key={index} href={button.link} target="_blank" rel="noopener noreferrer">
                <button className="card-button">{button.text}</button>
              </a>
            )}
          </div>
        </div>
      </div>

      {carousel && carousel.length > 0 && (
        <Modal 
          isOpen={modalIsOpen} 
          onRequestClose={toggleModal} 
          className="modal-style"
          shouldCloseOnOverlayClick={true}
        >
          <button onClick={toggleModal} className="close-modal-button">X</button>
          <Carousel>
            {carousel.map((item, index) => (
              <div key={index}>
                <img src={item.link} alt={item.image_description} />
                <div className="custom-legend">{item.image_description}</div> {/* Custom legend */}
              </div>
            ))}
          </Carousel>
        </Modal>
      )}
    </>
  );
};

export default Card;
