import React from 'react';
import '../css/About.css';

const About = () => (
  <section id="about" className="content-about">
    <h2>About</h2>
    <p className="about-paragraph">
      Hello there! I'm Wilson, a coder, tinkerer, and lifelong learner. 
      I have 5+ years of programming experience, mainly python,  working on personal and professional projects. 
      I have worked in many fields relating to tech, including cybersecurity, webscraping automation, web development, and embedded systems.
      
    </p>
    <p className="about-paragraph">
      My journey starts with finding vulnerbilities and exploits in various Roblox expirences.
      I spent hours on end carefully inspecting the code base of many games and coming up with creative workarounds to "autofarm" games.
      This would eventually shape my foundations, perspectives and principles around programming, which include: automate everything! and we have a serious cybersecurity problem!
      Following these principles allowed me to build business around game automation of mundane tasks, selling over 1.5k in products and generating over 10k in sales.
      
    </p>

    <p className="about-paragraph">
      I eventually found out that what I was programming in Roblox was along the lines of HTTP Requests and REST apis. 
      Naturally I have come to enjoy these topics within webscraping because of the many concepts that overlaped, and understanding these concepts allowed me to automate many aspects of life.
      I am currently working at Boeing to further my knowledge of automated web scraping so I can build better automatic web scripts. 
      My most recent script I build was for a language learning website called seedlang for my german class, and I became the top person in the world in a day out of a 100k people because I automated the point system. 
      I plan to write a cybersecurity blog with all my knowledge and release my scripts soon to fully fill out my portfolio when I get the time!
    </p>

    <p className="about-paragraph">
      
    </p>

  </section>
);

export default About;
