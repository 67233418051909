import React from 'react';
import '../css/BlogCard.css'; // Assuming you have a CSS file for styling

const BlogCard = ({ title, summary, imageUrl, link }) => {
    return (
        <div className="blog-card">
            <img src={imageUrl} className="blog-image" />
            <div className="blog-content">
                <h3 className="blog-title">{title}</h3>
                <p className="blog-summary">{summary}</p>
                <a href={link} className="blog-read-more">Read More</a>
            </div>
        </div>
    );
};

export default BlogCard;
