import React from 'react';
import '../css/ASCI112.css'; // Make sure the path matches the location of your CSS file
import Card from '../components/Card'; // Assuming you will use the Card component later
import otter from '../images/otterbaby.jpg'
import otter2 from '../images/twootters.jpg'
import otter3 from '../images/otter3.jpg'
import otter4 from '../images/otter4.jpg'
import Footer from '../components/Footer';
const ASCI112 = () => {
  return (
    <div className="ASCI112-page">
        <div className="ASCI112-side-left">
        <h1>The Otter</h1>
        <p>The otter is a semi-aquatic mammals which is known for their playful behavior and incredible agility in water. There are 13 species of otters, and most are small, with sleek bodies, short legs, and a tail. They have a thick fur coat that is water repellent, and this keeps them insulated against cold waters. Otters are often seen playing in groups, sliding around in the mud, and using rocks as tools to crack open shellfish, which is a staple for their diet. The otter's role in its ecosystem is to control the population of shellfish, and often serve as indicators of environmental health. Their habitat ranges from the kelp forests of the Pacific Ocean to the wetlands and inland waterways across the globe. </p>
        <p>I choose the otter because I like watching otters play and interact with each other on twitch livestream. I am interested in why they evolved to be so playful.</p>
      </div>
      <div className="ASCI112-side-right">
      <h1>Otters</h1>
        <div className="image-container">
          <img src={otter2} alt="Otter Image 1" />
          <img src={otter} alt="Otter Image 2" />
          <img src={otter3} alt="Otter Image 3" />
          <img src={otter4} alt="Otter Image 4" />
          </div>
          <Footer />
      </div>
    </div>
  );
};

export default ASCI112;
